.layout {
  min-height: 100vh;
}

.main-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px 60px;
}

header {
  display: flex;
  align-items: center;
}